<template>
  <f7-page
    class="main-report"
    name="home"
    @page:beforein="onPageBeforeIn"
    @page:beforeremove="onPageBeforeRemove"
  >
    <div>
      <navbar
        slot="fixed"
        :nav-title="titulo"
        menu-btn-enable
        :add-btn-enable="canCreate"
        :add-btn-callback="() => $f7.views.main.router.navigate('/newForm/',{reloadAll: true, history: false, ignoreCache: true})"
        refresh-btn-enable
      />
    </div>
    <f7-row
      no-gap
      class="content-calendar"
    >
      <f7-col :width="showSearchbarIcon ? 90 : 100">
        <f7-list
          inline-labels
          no-hairlines-md
        >
          <f7-list-input
            type="datepicker"
            required
            placeholder="Seleccione fecha"
            validate
            class="padding-top-half padding-bottom-half"
            :calendar-params="params"
            :value="fecha"
            @input="fecha = $event.target.value"
            @calendar:change="fecha = $event"
          >
            <f7-icon
              slot="media"
              icon="icon-calendar"
            />
          </f7-list-input>
        </f7-list>
      </f7-col>
      <f7-col
        v-if="showSearchbarIcon"
        width="10"
      >
        <f7-link @click="onSearchIcon">
          <f7-icon
            f7="search"
            style="color:white;padding-top:20px"
          />
        </f7-link>
      </f7-col>
    </f7-row>
    <f7-row v-if="showSearchbar">
      <f7-col>
        <f7-searchbar
          disable-button-text
          @input="getSearchKeyWords"
        />
      </f7-col>
    </f7-row>

    <f7-row v-if="getShowNotSync && nResponsesWihoutSync > 0">
      <f7-col style="padding:10px; background: #FF654F;color: white;">
        {{ showMessageSync }}
        <f7-button
          v-if="!showOnlyNotSync"
          outline
          small
          style="float: right;"
          @click="showWithoutSync()"
        >
          Ver
        </f7-button>
        <f7-button
          v-else
          outline
          small
          style="float: right;"
          @click="hideWithoutSync()"
        >
          Ocultar
        </f7-button>
      </f7-col>
    </f7-row>
    <div>
      <div
        v-if="!loadingResponses && getFilteredTemplates.length"
        class="content-list-report"
      >
        <div class="storybook-sorted-accordion-1">
          <f7-list
            strong
            outline-ios
            dividers-ios
            inset-md
            accordion-list
          >
            <f7-list-item
              v-for="(element, index) in getFilteredTemplates"
              :id="element.id"
              :key="index"
              class="sorted-accordion"
              accordion-item
              :title="element.name"
              :badge="element.responses.length"
              badge-color="color:#fff"
              style="background-color:#4A4A4A"
            >
              <f7-accordion-content
                style="background:#ffffff"
                class="accordion-item-content"
              >
                <hi-info-row
                  v-if="element.entityName"
                  :title="element.entityName"
                />

                <div
                  v-for="(suboption, index1) in element.responses"
                  :key="index1"
                  accordion-list
                  class="content-item-row"
                >
                  <div
                    class="item-content"
                    @click="openSheet(suboption)"
                  >
                    <div
                      class="title"
                      style="display:block"
                    >
                      {{ getResponseTitle(suboption) }}
                    </div>
                  </div>
                  <small
                    style="margin: 15px 15px 0 15px;"
                  >
                    <img
                      v-if="suboption.sync"
                      width="10px"
                      :src="iconCheck"
                      alt=""
                    >
                    <strong>{{ getResponseFormattedDates(suboption) }}</strong></small>

                  <hr>
                </div>
              </f7-accordion-content>
            </f7-list-item>
          </f7-list>
        </div>
        <!-- <hi-sorted-accordion
          v-for="(element, index) in getFilteredTemplates"
          :id="element.id"
          ref="resultsAccordion"
          :key="index"
          :title="element.name"
          :list-options="element.responses"
          :closed="default_closed"
          :show-badge="true"
          primary="#4A4A4A"
          font-color="#ffffff"
          :show-item-border-bottom="true"
          border-item-bottom-color="#E5EEF7"
        >
          <template #subtitle>
            <hi-info-row
              v-if="element.entityName"
              :title="element.entityName"
            />
          </template>

          <template slot-scope="element">
            <div @click="openSheet(element)">
              {{getResponseTitle(element)}} {{ getResponseFormattedDates(element) }}
            </div>
          </template>
        </hi-sorted-accordion> -->
      </div>

      <div v-if="!loadingResponses && !getFilteredTemplates.length">
        <div class="content-nodata">
          <img
            :src="iconTime"
            alt="time"
          >
          <div class="content-nodata__title">
            No hay informes
          </div>
          <div class="content-nodata__description">
            Pulse el botón <img
              :src="iconAdd"
              alt="add"
            > para crear un informe
          </div>
        </div>
      </div>

      <f7-list
        v-if="loadingResponses"
        media-list
        style="margin-top:20px"
        class="skeleton-text skeleton-effect-blink"
      >
        <f7-list-item
          v-for="n in 4"
          :key="n.index"
          title="Lorem ipsum dolor sit dolor sit"
          subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit.
           Praesent ultricies tortor ipsum, vitae elementum turpis elementum ac."
          footer="Lorem ipsum dolor sit dolor sit"
        />
      </f7-list>
    </div>

    <f7-sheet
      :opened="opened"
      :close-by-backdrop-click="false"
      :close-by-outside-click="true"
      :close-on-escape="false"
      class=""
      @sheet:closed="opened = false"
    >
      <div class="content-action-sheet border-top padding">
        <f7-block-header class="margin-bottom no-margin">
          ¿Qué quieres hacer?
        </f7-block-header>
        <div class="content-action-sheet__buttons">
          <div
            v-if="canShare"
            @click="sharePDF"
          >
            <div>
              <div>
                <img
                  :src="iconWhatsapp"
                  alt=""
                >
              </div>
            </div>
            <strong>Compartir</strong>
          </div>
          <div
            v-if="canEdit"
            @click="deleteResponseSelected"
          >
            <div>
              <div>
                <img
                  :src="iconTrash"
                  alt=""
                >
              </div>
            </div>
            <strong class="text-color-red">Eliminar</strong>
          </div>
          <div
            v-if="canEdit"
            @click="setResponseSelected"
          >
            <div>
              <img
                :src="iconEdit"
                alt=""
              >
            </div>
            <strong>Editar</strong>
          </div>

          <div
            v-else
            @click="setResponseSelected"
          >
            <div>
              <img
                :src="iconEdit"
                alt=""
              >
            </div>
            <strong>Ver</strong>
          </div>
        </div>
      </div>
    </f7-sheet>
  </f7-page>
</template>

<script>
import Navbar from '../components/Navbar.vue';
import store from '../js/store/index';
import Location from '../js/utilities/location';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import TemplateRepository from '../js/repositories/TemplateRepository';
import ResponseRepository from '../js/repositories/ResponseRepository';
import ResponseDeletesRepository from '../js/repositories/ResponseDeletesRepository';
// import HiSortedAccordion from 'vue-component-library/components/uitemplates/HiSortedAccordion.vue';
// import HiInfoRow from 'vue-component-library/components/uitemplates/HiInfoRow.vue';
// import HiSortedAccordion from '../components/uitemplates/HiSortedAccordion.vue';
import HiInfoRow from '../components/uitemplates/HiInfoRow.vue';
import icTime from '../static/img/ic_time.svg';
import icTrash from '../static/img/ic_trash.svg';
import icEdit from '../static/img/ic_edit.svg';
import icWhatsapp from '../static/img/ic_whatsapp.svg';
import icCheck from '../static/img/ic_checked.svg';

import icAdd from '../static/img/ic_new_orange.svg';
import EventBus from '../js/EventBus';
import StringUtils from '../js/utilities/StringUtils';
import Api from '../js/services/Api';

export default {
  name: 'Home',
  components: { Navbar, /*HiSortedAccordion,*/ HiInfoRow },
  data() {
    return {
      templates: [],
      opened: false,
      selected: null,
      fecha: '',
      currentFecha: '',
      calendarParams: {
        closeOnSelect: true,
        openIn: 'customModal',
        header: false,
        footer: true,
        dateFormat: 'yyyy-mm-dd',
        multiple: false,
        rangePicker: this.getSearchDataRange
      },
      canEdit: false,
      canCreate: false,
      canDelete: false,
      canShare: false,
      rol: this.$f7route.params.idRol,
      titulo: 'Inicio',
      //
      timerId: null,
      showSearchbar: false,
      searchbarKeyWords: [],
      //
      loadingResponses: false,
      current_code_print: '',
      last_modifications: [],
      default_closed: false,
      nResponsesWihoutSync: 0,
      showOnlyNotSync: false,
    };
  },
  computed: {
    getFilteredTemplates() {
      if (!this.searchbarKeyWords.length) return this.templates;
      //
      const filteredTemplates = [];
      const clonedTemplates = JSON.parse(JSON.stringify(this.templates))
      clonedTemplates.forEach(template => {
        const responses = [];
        template.responses.forEach(response => {
          if (response.info_entity && JSON.parse(response.info_entity)) {
            let excludeResponse = false;
            const searchText = this.$utils
              .removeDiacritics((JSON.parse(response.info_entity).name + ' -- ' + response.nameUser + ' -- ' + response.updated_at+ ' -- ' + response.sync))
              .toLowerCase();
            this.searchbarKeyWords.forEach(key => {
              if (!excludeResponse && !searchText.includes(key)) excludeResponse = true;
            });
            if(!excludeResponse) responses.push(response)
          } else {
            let excludeResponse = false;
            const searchText = this.$utils
              .removeDiacritics(( response.nameUser + ' -- ' + response.updated_at+ ' -- ' + response.sync))
              .toLowerCase();
            this.searchbarKeyWords.forEach(key => {
              if (!excludeResponse && !searchText.includes(key)) excludeResponse = true;
            });
            if(!excludeResponse) responses.push(response)
          }
        });
        if (responses.length) {
          template.responses = responses
          filteredTemplates.push(template)
        }
      });
      return filteredTemplates;
    },
    showSearchbarIcon() {
      return this.getFilteredTemplates.length || this.searchbarKeyWords.length
    },
    params() {
      return {
        closeOnSelect: true,
        openIn: 'customModal',
        header: false,
        footer: true,
        dateFormat: 'yyyy-mm-dd',
        multiple: false,
        rangePicker: this.getSearchDataRange
      }
    },
    getTemplates() {
      return this.templates;
    },
    showFecha() {
      return this.fecha;
    },
    iconTrash() {
      return icTrash
    },
    iconWhatsapp() {
      return icWhatsapp;
    },
    iconEdit() {
      return icEdit
    },
    iconTime() {
      return icTime;
    },
    iconAdd() {
      return icAdd;
    },
    iconCheck() {
      return icCheck;
    },
    showMessageSync() {
      return 'Hay ' + this.nResponsesWihoutSync + ' controles sin sincronizar';
    },
    ...mapState([
      'currentWorkingDate',
      'template',
      'currentRol',
      'token',
      'dayCountToEdit',
    ]),
    ...mapGetters(['getSearchDataRange', 'getCloseAccordion', 'getShowNotSync', 'getCheckResponse']),
    ...mapGetters('Responses',['getDatesSelected'])
  },
  watch: {
    async fecha(value) {
      this.SET_DATES(value)
      if(!this.getSearchDataRange
      || (this.getSearchDataRange && value.length === 2)) {
        this.loadingResponses = true
        await this.getList();
      }

      this.loadingResponses = false
    },
  },
  // updated(){
  //   console.log('home - updated')
  // },
  async created() {
   Location.getActualGPSLocation()
      .then((loc) => {
        if (loc) {
          store.commit('setLatitude', loc.coords.latitude);
          store.commit('setLongitude', loc.coords.longitude);
        }
      })
      .catch((e) => {
        console.error(e);
      });

    if (this.rol) {
      store.commit('setCurrentRol', this.rol);
    }


  },
  async mounted() {
    this.canEdit = false;
    this.canCreate = this.token.data.create;
    this.canDelete = this.token.data.delete;

    EventBus.$emit('responses.pending.upload');
    EventBus.$on('taskLoader.finished', this.getList);
    this.default_closed = this.getCloseAccordion;
  },
  beforeDestroy() {
    EventBus.$off('taskLoader.finished', this.getList);
    // window.removeEventListener('click', this.openSheet);
    // window.removeEventListener('click', this.setResponseSelected);
    // if (this.timerId) clearTimeout(this.timerId);
  },
  destroyed() {
    EventBus.$off('taskLoader.finished', this.getList);
    // window.removeEventListener('click', this.openSheet);
    // window.removeEventListener('click', this.setResponseSelected);
  },
  methods: {
    async onPageBeforeRemove() {
      console.log('home - onPageBeforeRemove')
    },
    async onPageBeforeIn() {
      const today = new Date();
      today.setHours(0,0,0,0);
      if(this.getDatesSelected) {
        this.fecha = this.getDatesSelected;
      } else {
        this.fecha = this.getSearchDataRange
          ? [new Date(today), new Date(today)]
          : [new Date(today)];
      }
      this.currentFecha = this.fecha;


    if(!this.getSearchDataRange
      || (this.getSearchDataRange && this.fecha.length === 2) && !this.fecha.every(i => this.currentFecha.includes(i))) {
        this.loadingResponses = true
        await this.getList();
      }

    this.loadingResponses = false
    },
    ...mapActions([
      'setCurrentWorkingDate'
    ]),
    ...mapMutations('Responses', [
      'SET_DATES'
    ]),
    onSearchIcon() {
      this.showSearchbar = !this.showSearchbar
      if (!this.searchbar) this.searchbarKeyWords = []
    },
    async getSearchKeyWords(e) {
      const getKeyWords = (e) => {
        if (e.target.value.trim()) {
        this.searchbarKeyWords = this.$utils
          .removeDiacritics(e.target.value.trim())
          .toLowerCase()
          .split(' ')
        } else {
          this.searchbarKeyWords = []
        }
      };
      if (this.timerId) clearTimeout(this.timerId);
      this.timerId = setTimeout(() => getKeyWords(e), 300);
    },
    getResponseTitle(responseData) {
      if (responseData.info_entity !== 'null') {
        return JSON.parse(responseData.info_entity).name;
      }
      return responseData.nameUser;
    },
    getResponseFormattedDates(responseData) {
      const created = this.formatDate(responseData.created_at);
      const updated = this.formatDate(responseData.updated_at);

      if (created == updated) {
        return `Creado por ${responseData.nameUser}: ${created}`;
      }

      return `Actualizado por ${responseData.nameUser}: ${updated}`;
    },
    /**
     * Set the posibility to edit. Using the Date and a count
     * @param {Date} date
     */
    processIfCanEditOrNotForAGivenDate(date) {
      const msDiff = Math.max(Date.now() - date.getTime());
      const hoursDiff = msDiff / (3600 * 1000);

      let daysDiff = Math.floor(hoursDiff / 24);
      // Clamp to positive
      daysDiff = Math.max(daysDiff, 0);

      this.canEdit = this.dayCountToEdit >= daysDiff;
    },
    async getList() {
      this.$f7.dialog.preloader('Cargando los formularios...');
      let date = this.fecha[0];
      this.processIfCanEditOrNotForAGivenDate(date);

      this.setCurrentWorkingDate(date);
      const todayDateStr = StringUtils.dateToDateString(date);

      if (this.rol) {
        let tmp = this.token.data.roles.find(
          (element) => element.id === this.rol
        );
        this.titulo = 'Informes ' + tmp.name;
      }

      this.templates = [];
      let templatesTemp = [];
      let templatesFinal = [];
      templatesTemp = await TemplateRepository.getAll();

      // Control respuestas sin sincronizaar
      let withoutSync = 0;

      for(let row of templatesTemp) {
        let respuestas;
        if(this.getSearchDataRange && this.fecha.length == 2) {
          let rangeDates = this.fecha;
          respuestas = await ResponseRepository.getByTemplateRangoFecha(
              row.id,
              rangeDates,
              this.rol
            );
        } else if(this.rol){
            respuestas = await ResponseRepository.getByTemplateFechaRol(
              row.id,
              todayDateStr,
              this.rol
            );
        }
        else {
            respuestas = await ResponseRepository.getByTemplateFecha(
              row.id,
              todayDateStr
            );
        }
        respuestas.filter((element) => {
          if(!element.sync) {
            withoutSync++;
          }
        });
        this.nResponsesWihoutSync = withoutSync;

        row.responses = respuestas;
        if (row.responses.length > 0)
          templatesFinal.push(row);
      }
      this.templates = templatesFinal;
      this.$f7.dialog.close();
    },
    async setResponse(response) {
      store.commit('setCurrentResponse', response);
      await TemplateRepository.getById(response.template).then((element) => {
        store.commit('setCurrentTemplate', element);
        this.$f7.views.main.router.navigate('/detailresponse/');
      });
    },
    setTemplate(id) {
      store.commit('setCurrentTemplate', id);
      this.$f7.views.main.router.navigate('/detailTemplate/');
    },
    openSheet(element) {
      this.opened = true;
      this.selected = element;

      TemplateRepository.getById(element.template).then((el) => {
        if(el.code_print) {
          this.current_code_print = el.code_print;
          this.canShare = true;
        }
      });
    },
    async setResponseSelected() {
      this.$f7.dialog.preloader('Cargando el formulario...');
      this.opened = false;

      // Parametro configurable por cliente: CHECK_RESPONSE
      // Debido a que hay clientes en los que un control es posible que sea modificado por varios trabajadores
      // Si el trabajador 2 no se ha actualizado los controles, es posible que tenga una versión de la respuesta antigua a la dejada por el trabajador 1
      // De esta forma nos aseguramos que el trabajador 2 tenga la última versión de la respuesta
      if(this.getCheckResponse && this.selected.idServer) {
        const response_server = await Api.getResponseById(this.selected.idServer);
        await ResponseRepository.updateById(this.selected.id, response_server);
      }

      try {
        // Obtenemos la ultima version de la respuesta en bd, es posible que lo que haya cargado en pantalla no sea lo ultimo
        const tmp1 = await ResponseRepository.getById(this.selected['id']);
        if(tmp1 === undefined) {
          this.$f7.dialog.close();
          this.$f7.dialog.alert('La respuesta no existe');
          return;
        }
        store.commit('setCurrentResponse', tmp1);
        store.commit('setCurrentResponseEditable', this.canEdit);
        const tmp2 = await TemplateRepository.getById(this.selected.template);
        store.commit('setCurrentTemplate', tmp2);
        this.$f7.views.main.router.navigate('/detailresponse/',{reloadAll: true, history: false, ignoreCache: true});

      } catch (error) {
        console.log('error: ' + error)
        this.$f7.dialog.close();
        this.$f7.dialog.alert('Error: ' + error);
      }
    },
    async deleteResponseSelected() {
      let date = this.fecha[0];
      const currentDate = StringUtils.dateToDateString(date);

      if (this.canDelete) {
        this.$f7.dialog.confirm('¿Seguro que desea eliminar?', async () => {
          if (this.selected.idServer) {
            // Ya había sido sincronizado con el back, se tiene que eliminar también de ahí
            let itemEliminar = {
              idServer: this.selected.idServer,
              sent: 0,
            };
            await ResponseDeletesRepository.add(itemEliminar);
            await ResponseRepository.deleteById(this.selected.id);
            await this.getList(currentDate);

            EventBus.$emit('responses.pending.upload');
          } else {
            // Este registro no se ha sincronizado en el back, únicamente se elimina de local
            await ResponseRepository.deleteById(this.selected.id);
            await this.getList(currentDate);
          }
        });
      } else {
        this.$f7.dialog.alert('No tienes permisos para realizar esta acción');
      }
    },
    formatDate(date) {
      if(date.includes('+')) {
        date = date.split('+')[0];
      }
      date = new Date(date);
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      return `${year}-${month}-${day} ${this.getHoursMinutesSecondsFromDate(
        date
      )}`;
    },
    getHoursMinutesSecondsFromDate(date) {
      return date.toTimeString().substr(0, 8);
    },
    async sharePDF() {
      this.$f7.dialog.preloader('Obteniendo el pdf...');
      const response = this.selected;
      console.log('response', response);
      let namePDF = 'calidad.pdf';
      try {
        if(response.info_entity && response.info_entity !== 'null') {
          namePDF = JSON.parse(response.info_entity).name + '.pdf';
          namePDF = namePDF.replace(/[/\\?%*:|"<>]/g, '-');
        } else {
          namePDF = response.nameUser + '-' + response.created_at + '.pdf';
          namePDF = namePDF.replace(/[/\\?%*:|"<>]/g, '-');
        }
      } catch (error) {
        namePDF = 'calidad.pdf';
      }
      console.log('namePDF', namePDF);

      const respCodeExt = await Api.getExternalCode(response.idServer)

      if(respCodeExt.code_external) {
        if(respCodeExt.code_external.includes('/')) {
          const serie = respCodeExt.code_external.split('/')[0];
          const numero = respCodeExt.code_external.split('/')[1];

          const register = await Api.getRegisterId(serie,numero);

          if(register.valor !== undefined && register.valor === false) {
            this.$f7.dialog.close();
            this.$f7.dialog.alert('No se ha podido obtener el registro de calidad');
            return;
          }
          if(register && register.length > 0) {
            const paramTemp = [{
              'ParameterName': 'idcontrolcalidad',
              'ParameterValue': register[0].id + ''
            }]

            const pdf = await Api.getPDF(paramTemp, this.current_code_print);

            this.$f7.dialog.close();
            if(!pdf.PDF && pdf.Error) {
              this.$f7.dialog.alert('Error obteniendo el pdf: ' + pdf.Error);
              this.$f7.views.main.router.navigate('/home/');
            } else {
              this.socialSharing(pdf.PDF, namePDF);
            }
          }
          else {
            this.$f7.dialog.alert('No se ha podido obtener el registro de calidad para obtener su pdf. Pruebe a compartirlo desde el listado');
            this.$f7.views.main.router.navigate('/home/');
          }
        }
      } else {
        this.$f7.dialog.close();
        this.$f7.dialog.alert('Aún no se ha sincronizado el formulario. Pruebe a compartirlo desde el listado');
        this.$f7.views.main.router.navigate('/home/');
      }
    },
    socialSharing(pdf, namePDF) {
      let name = 'calidad.pdf';
      if(namePDF) {
        name= namePDF;
      }
      const pdfTmp = new File([pdf], name, { type: 'application/pdf' });
      if(!this.$f7.device.cordova) {

        this.$f7.dialog.confirm('¿Seguro que desea compartir?', async () => {
          navigator.share({
            title: this.token.data.full_name,
            text: name,
            files: [pdfTmp]
          })
        });
      } else {
        window.plugins.socialsharing.share(this.token.data.full_name, name, 'data:application/pdf;base64,'+pdf, null);
      }
      try {
          window.FirebasePlugin.logEvent('SharePDF', { content_type: 'Events', item_id: 'SharePDF' });
      } catch (err) {
        console.log(err);
      }
    },
    showWithoutSync() {
      this.searchbarKeyWords = ['false'];
      this.showOnlyNotSync = true;
    },
    hideWithoutSync() {
      this.searchbarKeyWords = [];
      this.showOnlyNotSync = false;
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fontSize20 {
  font-size: 20px;
}
.fontSize30 {
  font-size: 30px;
}
.icon-calendar {
  background-image: url("../static/img/ic_calendar.svg");
  width: 24px;
  height: 24px;
}

.main-report {
  .page-content {
    padding-top: 0;
  }
  .content-calendar {
    background-color: var(--ha-dimgray);
    .list {
      .item-input {
        flex-direction: row-reverse;
        .item-media {
          padding-top: 0;
          align-self: center;
          padding-right: 5px;
          margin-left: 5px;
          .icon {
            width: 45px;
            height: 45px;
          }
        }

        .item-inner {
          background-color: #5f6a8c;
          border-radius: 3px;
          margin-left: 0;
          input {
            color: #dddddd !important;
            border: none !important;
            &::placeholder {
              color: #dddddd !important;
            }
          }
        }
      }
    }
  }

  .content-list-report {
    .accordion-list {
      .badge {
        background-color: var(--ha-dimgray);
      }
      li {
        .item-link {
          .item-title {
            text-transform: uppercase;
            padding-left: 10px;
          }
          .item-after {
            margin-left: 0;
            padding-left: 0;
          }
        }
        .accordion-item-content {
          .content-item-row {
            ::before {
              display: flex;
              align-items: center;
              height: var(--f7-list-item-min-height);
            }
            .item-content {
              background: #f7f8fa;
              border-radius: 6px;
              margin: 15px 15px 0 15px;
              padding: 15px;
              box-sizing: content-box;
              .item-inner {
                padding: 0;
                .item-title {
                  color: var(--ha-darkblue);
                  padding-left: 0;
                }
                &::after {
                  height: 0;
                }
              }
            }
            &:last-child {
              margin-bottom: 15px;
            }
            .item-link {
              background-color: #ffffff;
              .item-inner {
                &::before {
                  display: none;
                }
              }
            }
            .item-subtitle {
              padding-top: 5px;
              span {
                font-weight: 500;
                font-size: 18px;
                line-height: 21px;
              }
            }
            .item-text {
              font-size: 12px;
              line-height: 14px;
              color: var(--ha-dimgray);
            }

            .item-media {
              align-self: center;
              padding: 0;
            }
            &__cantidad {
              color: #798dc8;
            }
            &__origen {
              color: var(--ha-yellow);
              padding-left: 15px;
            }
          }
        }
      }
      &.title-accordion {
        &::after {
          z-index: 2;
          position: absolute;
          top: 44px;
          left: 18px;
          content: "";
          width: 0;
          height: 0;
          border-top: solid 10px var(--ha-blue);
          border-left: solid 10px transparent;
          border-right: solid 10px transparent;
        }
      }
    }
  }

  .sorted-accordion {
    margin-bottom: 1px;
    .main-info-row {
      background-color: var(--ha-lightsnow);
      border-bottom: 1px solid var(--ha-lightsnow);
      .item-inner {
        background-color: var(--ha-lightsnow) !important;
        .item-title {
          color: var(--ha-dimblue);
          font-size: 12px;
          text-transform: uppercase;
          font-weight: 400;
        }
      }
      &:after {
        border-top: solid 10px var(--ha-lightsnow);
        filter: drop-shadow(0px 1px 0px var(--ha-lightsnow));
      }
    }
    .content-item-row {
      .item-content {
        padding: 0 !important;
        background-color: transparent !important;
        .item-inner {
          .item-subtitle {
            color: #a5a9b8;
          }
        }
      }
    }
    .content-item-row-new {
      .item-content {
        padding: 0 !important;
        background-color: transparent !important;
        .item-inner {
          .item-subtitle {
            color: #fff;
          }
        }
      }
    }
  }
  .accordion-list .badge {
    background-color: #373737;
  }

  .content-nodata {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;
    &__title {
      font-size: 18px;
      font-weight: 500;
      margin-top: 10px;
      color: #a5a9b8;
    }
    &__description {
      font-size: 14px;
      font-weight: 300;
      margin-top: 10px;
      color: #a5a9b8;
      display: flex;
      align-items: center;
      img {
        width: 19px;
        height: 19px;
        margin: 0 4px;
      }
    }
  }
}
.content-action-sheet {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .block-header {
    text-align: center;
    color: var(--ha-darkblue);
    font-weight: 500;
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 48px;
        height: 48px;
      }
      span {
        font-weight: 400;
        font-size: 12px;
        color: #77869e;
        padding-top: 7px;
      }
      &:first-child {
        span {
          color: #ff654f;
        }
      }
    }
  }
}



.storybook-sorted-accordion-1 {
  .accordion-list {
    --f7-list-item-title-text-color: #ffffff;
    --f7-badge-bg-color: var(--his-darkgray);
    --f7-badge-text-color: #ffffff;
    .sorted-accordion {
      > .item-link {
        .item-title {
          padding-left: 10px;
          width: 100%;
        }
        background-color: '#4A4A4A';
      }
      .item-inner {
        flex-direction: row-reverse;
        justify-content: flex-end;
        &::before {
          color: var(--custom-font-color);
        }
      }
      .item-after {
        margin-left: 0;
        padding-left: 0;
      }
      .accordion-item-content {
        .content-item-row {
          .item-inner {
            border-bottom: 1px solid var(--f7-theme-color-gray-tint);
          }
        }
      }
    }
  }
  .left-chevron{
    .item-link{
      .item-content{
        .item-inner{
          .item-title{
            padding-left: 25px;
          }
          .item-after{
            margin-left: 25px;
            margin-right: -10px;
          }
          &::before{
            left: 0;
            right: 0;
            margin-top: -3px!important;
            margin-right: 15px;
          }
        }
      }
    }
  }
}
</style>
