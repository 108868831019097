import Api from './Api';
import db from './Database';
import ResponseRepository from '../repositories/ResponseRepository';
import ResponseDeletesRepository from '../repositories/ResponseDeletesRepository';
import store from '../store/index';

import EventBus from '../EventBus';
import EntityCacheRepository from '../repositories/EntityCacheRepository';

// HACK
EventBus.$on('taskLoader.finished', () => {
  localStorage.setItem('lastUpdate', Date.now());
});

export default {
  async refresh() {
    let deltaUpdate = true;

    const lastUpdate = localStorage.getItem('lastUpdate');
    if (!lastUpdate) {
      deltaUpdate = false;
    } else {
      const diff = Date.now() - Number(lastUpdate);
      const WEEK = 1000 * 60 * 60 * 24 * 7;

      if (diff > WEEK) {
        deltaUpdate = false;
      }
    }

    if (deltaUpdate) {
      this.pushDeltaDownloadTasks();
    } else {
      this.pushFullDownloadTasks();
    }

    this.pushCommonTasks();

    return 'ok';
  },

  async refreshOnlyTemplates() {
    this.pubshFullDownladOnlyTemplates();
    return 'ok';
  },

  // ======================================================

  async deleteAllTasks() {
    await db.delete();
    return 'ok';
  },

  // ======================================================
  pushDeltaDownloadTasks() {
    EventBus.$emit(
      'taskLoader.push',
      {
        name: 'Obteniendo nuevas plantillas de formularios',
        asyncTask: this.downloadTemplateDeltas
      }
    );
    EventBus.$emit(
      'taskLoader.push',
      {
        name: 'Obteniendo nuevas versiones de formularios',
        asyncTask: this.downloadTemplateVersionDeltas
      }
    );

    EventBus.$emit(
      'taskLoader.push',
      {
        name: 'Obteniendo nuevas informes',
        asyncTask: this.downloadResponseDeltas
      }
    );
  },

  // ======================================================
  pushFullDownloadTasks() {
    EventBus.$emit(
      'taskLoader.push',
      {
        name: 'Obteniendo plantillas de formularios',
        asyncTask: this.downloadTemplates
      }
    );

    EventBus.$emit(
      'taskLoader.push',
      {
        name: 'Obteniendo versiones de formularios',
        asyncTask: this.downloadTemplateVersions
      }
    );

    EventBus.$emit(
      'taskLoader.push',
      {
        name: 'Obteniendo informes',
        asyncTask: this.downloadReponses
      }
    );
  },

  pubshFullDownladOnlyTemplates() {
    EventBus.$emit(
      'taskLoader.push',
      {
        name: 'Obteniendo plantillas de formularios',
        asyncTask: this.downloadTemplates
      }
    );

    EventBus.$emit(
      'taskLoader.push',
      {
        name: 'Obteniendo versiones de formularios',
        asyncTask: this.downloadTemplateVersions
      }
    );
  },

  // ======================================================
  pushCommonTasks() {
    EventBus.$emit(
      'taskLoader.push',
      {
        name: 'Obteniendo datos de configuración',
        asyncTask: this.downloadConfDataTypes
      }
    );

    EventBus.$emit(
      'taskLoader.push',
      {
        name: 'Obteniendo servicios',
        asyncTask: this.downloadServices
      }
    );

    EventBus.$emit(
      'taskLoader.push',
      {
        name: 'Obteniendo datos de caché',
        asyncTask: this.downloadCacheData
      }
    );
  },

  async uploadPendingResponses() {
    if(store.state.responsesStatus){
      console.log('Upload aborted by concurrent use');
      return;
    }
    store.commit('setResponseStatus', true);
    console.log('Uploading pending responses - Init');
    try {
      const pending = await ResponseRepository.getPendingToUpload();

      for (let i = 0; i < pending.length; i++) {
        const item = pending[i];
        const idLocal = item.id;

        const response = await Api.saveTemplateResponse(item)
        if(response.idServer) {
          await ResponseRepository.updateById(idLocal, response);
        }
      }

      const pendingToRemove = await ResponseDeletesRepository.getPending();
      for (let i = 0; i < pendingToRemove.length; i++) {
        const item = pendingToRemove[i];
        const idLocal = item.id;

        await Api.deleteTemplateResponse(item.idServer);
        await ResponseDeletesRepository.deleteById(idLocal);
      }
    } catch (reason) {
      console.error(reason);
    } finally{
      store.commit('setResponseStatus', false);
      console.log('Uploading pending responses - End');
    }
  },

  // async uploadPendingResponses() {
  //   if(store.state.responsesStatus){
  //     console.log('Upload aborted by concurrent use');
  //     return;
  //   }
  //   store.commit('setResponseStatus', true);
  //   console.log('Uploading pending responses - Init');
  //   try {
  //     const pending = await ResponseRepository.getPendingToUpload();

  //     for (let i = 0; i < pending.length; i++) {
  //       try {
  //         const item = pending[i];
  //         const idLocal = item.id;

  //         const response = await Api.saveTemplateResponse(item)
  //         await ResponseRepository.updateById(idLocal, response);
  //       } catch (error) {
  //         alert('saveTemplateResponse:' + error);
  //       }
  //     }

  //     const pendingToRemove = await ResponseDeletesRepository.getPending();
  //     for (let i = 0; i < pendingToRemove.length; i++) {
  //       try {
  //         const item = pendingToRemove[i];
  //         const idLocal = item.id;

  //         await Api.deleteTemplateResponse(item.idServer);
  //         await ResponseDeletesRepository.deleteById(idLocal);
  //       } catch (error) {
  //         alert('deleteTemplateResponse:' + error)
  //       }

  //     }
  //   } catch (reason) {
  //     console.error(reason);
  //   } finally{
  //     store.commit('setResponseStatus', false);
  //     console.log('Uploading pending responses - End');
  //   }
  // },

  // Individual methods
  async downloadTemplateDeltas() {
    const templates = await Api.getTemplatesNew()
    if (templates && templates.length > 0) {
      for (let i = 0; i < templates.length; i++) {
        const item = templates[i];
        const existe = await db.templates.where('id').equals(parseInt(item.id, 10)).first();
        if (existe) {
          db.templates.update(item.id, item);
        } else {
          db.templates.add(item);
        }
      }
    }
  },

  async downloadTemplateVersionDeltas() {
    const vtemplates = await Api.getVersionsTemplatesNew()
    if (vtemplates && vtemplates.length > 0) {
      for (let i = 0; i < vtemplates.length; i++) {
        const item = vtemplates[i];
        const existe = await db.versionsTemplates.where('id').equals(parseInt(item.id, 10)).first();
        if (existe) {
          db.versionsTemplates.update(item.id, item);
        } else {
          db.versionsTemplates.add(item);
        }
      }
    }
  },

  async downloadResponseDeltas() {
    const responses = await Api.getResponsesNew();
    if (responses) {
      for (let i = 0; i < responses.length; i++) {
        const item = responses[i];
        const existe = await db.responses.where('idServer').equals(parseInt(item.idServer, 10)).first();
        if (existe) {
          db.responses.update(existe.id, item);
        } else {
          db.responses.add(item);
        }
      }
    }
  },

  async downloadConfDataTypes() {
    const datatypesconf = await Api.getConfDatatype();
    store.commit('setConfDatatypes', datatypesconf);
    await db.datatypes.clear();
    await db.datatypes.bulkPut(datatypesconf);
  },

  async downloadTemplates() {
    const templates = await Api.getTemplates();
    await db.templates.clear();
    await db.templates.bulkPut(templates);
  },

  async downloadTemplateVersions() {
    const versionsTemplates = await Api.getVersionsTemplates();
    await db.versionsTemplates.clear();
    await db.versionsTemplates.bulkPut(versionsTemplates);
  },

  async downloadReponses() {
    const responsesData = await Api.getResponses();
    await db.responses.clear();
    await db.responses.bulkPut(responsesData);
  },

  async downloadServices() {
    const services = await Api.getServices();
    await db.services.clear();
    await db.services.bulkPut(services);

    const servicesAll = await Api.getAllServices();
    await db.allservices.clear();
    await db.allservices.bulkPut(servicesAll);
  },

  async downloadCacheData() {
    const cacheItems = [];
    const transformCacheData = (rawItem) => {
      const code = rawItem.code;
      const rawdata = rawItem.value;

      try {
        const data = JSON.parse(rawdata);

        data.forEach((item) => {
          cacheItems.push({
            __code__: code,
            ...item,
          });
        })
      } catch (e) {
        console.error(e);
      }
    }

    const companyCache = await Api.getServicesCompanyFromCache();
    companyCache.forEach(transformCacheData);

    const userCache = await Api.getServicesUserFromCache();
    userCache.forEach(transformCacheData);

    await EntityCacheRepository.resetWith(cacheItems);
  },
};
