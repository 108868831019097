<template>
  <f7-popup
    :opened="opened"
    :tablet-fullscreen="true"
    @popup:closed="$emit('closed')"
    @popup:open="onOpen"
  >
    <f7-page>
      <f7-navbar :title="enttName">
        <f7-nav-right>
          <f7-link
            popup-close
          >
            Cerrar
          </f7-link>
        </f7-nav-right>
      </f7-navbar>
      <div class="padding-top-half content-searchbar-popup">
        <f7-row
          no-gap
          class="align-items-center"
        >
          <f7-col width="100">
            <f7-searchbar
              :value="searchbarValue"
              disable-button-text
              @input="search"
            />
          </f7-col>
          <!-- <f7-col width="20">
            <f7-segmented
              class="padding-horizontal-half"
            >
              <f7-button
                fill
                small
                class="no-padding no-margin"
                icon-f7="qrcode"
                @click="$emit('open-qr-reader')"
              />
              <f7-button
                fill
                small
                class="no-padding no-margin"
                icon-f7="barcode"
                @click="$emit('open-barcode-reader')"
              />
            </f7-segmented>
          </f7-col> -->
        </f7-row>
      </div>

      <f7-block
        v-if="isLoading"
        class="padding text-align-center"
      >
        <f7-icon
          v-if="hasError"
          f7="multiply_square_fill"
          color="red"
        />

        <f7-preloader v-else />

        <f7-block-header class="margin">
          {{ loadingStatusLabel }}
        </f7-block-header>

        <p
          v-if="hasError"
          class="padding"
        >
          {{ loadingErrorLabel }}
        </p>
      </f7-block>

      <f7-block
        v-else
        class="no-padding"
      >
        <f7-block-header class="padding-top-half padding-left-half">
          <strong>{{ loadingStatusLabel }}</strong><br>
          <span>Mostrando {{ entitySearchResultsLimited.length }} de </span>

          <span v-if="searchText">
            {{ entitySearchResults.length }} resultados
          </span>
          <span v-else>
            {{ entityFullList.length }} resultados
          </span>
          <span v-if="hasError">
            (sin conexión)
          </span>
        </f7-block-header>

        <!-- <f7-list media-list> -->
        <ul
          class="list media-list onScroll-height"
          @scroll="loadMore"
        >
          <f7-list-item
            v-for="(entt, index) in entitySearchResultsLimited"
            :key="index"
            :title="entt.name"
            link
            style="border-bottom: 1px solid rgba(0, 0, 0, 0.12);"
            @click="enttSelected(entt.id)"
          />
        </ul>
        <!-- </f7-list> -->
      </f7-block>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import EventBus from '../js/EventBus';

export default {
  props: {
    opened: { type: Boolean, default: false },
    enttCode: { type: String, default: '' },
    enttId: { type: String, default: '' },
    enttName: { type: String, default: '' },
  },
  data () {
    return {
      page: 1,
      allowInfinite: true,
      showPreloader: true,
      searchbarValue: ''
    }
  },
  computed: {
    ...mapState('EntityCacheSearch', [
      'isLoading',
      'loadingStatusLabel',
      'hasError',
      'loadingErrorLabel',
      'searchText',
      'entitySearchResults',
      'entityFullList',
    ]),
    ...mapGetters('EntityCacheSearch', [
      'entitySearchResultsLimited',
    ]),
  },
  watch: {
    page(val) {
      this.SET_PAGE(val)
    }
  },
  mounted() {
    this.searchEntities('');
    EventBus.$on('code-scanned', (scannedCode) => {
      this.searchbarValue = scannedCode;
      this.search(scannedCode, true);
    });
  },
  beforeDestroy() {
    EventBus.$off('code-scanned');
  },
  methods: {
    ...mapMutations('EntityCacheSearch', ['SET_PAGE']),
    ...mapActions('EntityCacheSearch', [
      'loadEntitiesByCodeFilter',
      'searchEntities',
    ]),
    onOpen() {
      console.log('onOpen', this.enttId)
      this.loadEntitiesByCodeFilter({code: this.enttCode, id: this.enttId});
    },
    search(ev, isScanndeValue = false) {
      const searchQuery = isScanndeValue ? ev : ev.target.value;
      this.page = 1;
      this.searchEntities(searchQuery);
    },
    enttSelected(id) {
      this.$emit('item-selected', id);
      this.$emit('closed');
    },
    loadMore(e) {
			let { scrollTop, clientHeight, scrollHeight } = e.target;
			if (!this.loading && scrollTop + clientHeight >= scrollHeight*4/5) {
				this.loading = true;
				setTimeout(() => {
					this.page++;
					this.loading = false;
				}, 1000);
      }
		}
  }
};
</script>

<style lang="scss">
  .onScroll-height {
    overflow: scroll;
    max-height: 80vh;
  }
  .content-searchbar-popup{
    .searchbar{
      background-color: none;
    }
    --f7-button-box-shadow: 0;
    --f7-searchbar-bg-color: var(--ha-lightgray)!important;
  }
</style>
