
import Api from '../services/Api';
import * as Config from '../../js/constants';

export default {
  async registerDevice(registrationData) {
    const data = {
      token_noti: registrationData.notificationsToken,
      platform: registrationData.platform,
      ident: registrationData.uuid,
      name: registrationData.name,
      type_app: Config.APP_TYPE,
    };
    const result = await Api.registerDevice(data);
    return result;
  },
  async fetchNotifications(date) {
    const data = {
      fecha: date,
    };
    const result = await Api.fetchNotifications(data);
    return result;
  },
  async sendNotification(idTemplate) {
    const data = {
      id_template: idTemplate,
    };
    const result = await Api.sendNotification(data);
    return result;
  },
  async checkAsRead(idNotification) {
    const result = await Api.checkAsRead(idNotification);
    return result;
  },
  async checkAsReadAll() {
    const result = await Api.checkAllAsRead();
    return result;
  },
};