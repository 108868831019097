<template>
  <f7-page
    name="notifications"
    :page-content="false"
  >
    <div>
      <navbar
        slot="fixed"
        nav-title="Notificaciones"
        menu-btn-enable
      />
    </div>
    <div
      style="padding-bottom: 50px"
    >
      <div class="content-calendar">
        <f7-list
          inline-labels
          no-hairlines-md
        >
          <f7-list-input
            type="datepicker"
            required
            placeholder="Seleccione fecha"
            validate
            class="padding-top-half padding-bottom-half"
            :calendar-params="params"
            :value="[fecha]"
            @calendar:change="changeDate"
          >
            <f7-icon
              slot="media"
              icon="icon-calendar"
            />
          </f7-list-input>
        </f7-list>
      </div>

      <div class="content-list-template">
        <div v-if="notifications.length > 0">
          <f7-block style="padding: 10px;">
            <f7-row>
              <f7-col width="100">
                <f7-button
                  fill
                  small
                  style="float: right;"
                  @click="setCheckReadAll()"
                >
                  Marcar todas como leídas
                </f7-button>
              </f7-col>
            </f7-row>
          </f7-block>
          <f7-list media-list>
            <f7-list-item
              v-for="(element, index) in notifications"
              :key="index"
              link="#"
              class="content-notifications-list-item"
              :class="{ 'not-read': !element.read_receiver }"
              :title="element.type"
              :text="element.text"
              :subtitle="formatDate(element.created_at)"
              :no-chevron="true"
              @click="setCheckRead(element)"
            />
          </f7-list>
        </div>
        <div v-else>
          <div class="content-nodata">
            <img
              :src="iconTime"
              alt="time"
            >
            <div class="content-nodata__title">
              No hay Notificaciones
            </div>
          </div>
        </div>
      </div>
    </div>
  </f7-page>
</template>

<script>
import Navbar from '../components/Navbar.vue';
import { mapState } from 'vuex';
import NotificationsDeviceRepository from '../js/repositories/NotificationsDeviceRepository';
import icTime from '../static/img/ic_time.svg';

export default {
  name: 'Notifications',
  components: { Navbar },
  data() {
    return {
      templateSearchQuery: '',
      notifications: [],
      opened: false,
      selected: null,
      fecha: new Date(),
      calendarParams: {
        closeOnSelect: true,
        openIn: 'customModal',
        header: false,
        footer: true,
        dateFormat: 'yyyy-mm-dd',
        multiple: false,
      },
      hoyMilis: null,
      rol: this.$f7route.params.idRol,
      titulo: '',
    };
  },
  computed: {
    params() {
      return {
        closeOnSelect: true,
        openIn: 'customModal',
        header: false,
        footer: true,
        dateFormat: 'yyyy-mm-dd',
        multiple: false,
        rangePicker: false
      }
    },
    iconTime() {
      return icTime;
    },

    ...mapState(['currentRol']),
    ...mapState(['token']),
    ...mapState(['confDatatypes']),
  },
  async mounted() {
  },
  methods: {
    async getList() {
      this.notifications = [];
      this.notifications = await NotificationsDeviceRepository.fetchNotifications(this.formatDateToYYYYMMDD(this.fecha));
    },
    async changeDate(e) {
      if (e && e[0]) this.fecha = e[0];
      await this.getList();
    },
    formatDateToYYYYMMDD(date) {
      date = new Date(date);
      let day = String(date.getDate()).padStart(2, '0');
      let month = String(date.getMonth() + 1).padStart(2, '0');
      let year = date.getFullYear();

      return `${year}-${month}-${day}`;
    },
    formatDate(date) {
      date = new Date(date);
      let day = String(date.getDate()).padStart(2, '0');
      let month = String(date.getMonth() + 1).padStart(2, '0');
      let year = date.getFullYear();

      let hours = String(date.getHours()).padStart(2, '0');
      let minutes = String(date.getMinutes()).padStart(2, '0');
      let seconds = String(date.getSeconds()).padStart(2, '0');

      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    },
    async setCheckRead(notification) {
      if (!notification.read_receiver) {
        const result = await NotificationsDeviceRepository.checkAsRead(notification.id);
        console.log('result checkAsRead', result);
      }
    },
    async setCheckReadAll() {
      const result = await NotificationsDeviceRepository.checkAsReadAll();
        console.log('result checkAsReadAll', result);
        this.getList();
    },
  },
};
</script>
<style lang="scss">
.content-notifications-list-item{
  .item-title{
    font-size: 16px;
    font-weight: 300!important;
    color: var(--his-blue-shade);
  }
  .item-after{
    color: var(--his-oliva-shade);
    font-size: 13px;
    font-weight: 400;
    opacity: 0.7;
    background: var(--his-oliva-tint);
    padding: 2px 12px;
    border-radius: 20px;
  }
  .item-text{
    margin-top: 16px;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    display: inline;
    white-space: normal;
    -webkit-box-orient: vertical;
    overflow: unset ;
    text-overflow: unset;
    -webkit-line-clamp: unset;
  }
  .item-subtitle{
    color:var(--his-oliva-tint);
  }
  .item-media img{
    width:50px;
    height: auto;
  }
}

.not-read {
  // background-color: aquamarine;
  .item-title{
    font-weight: 500 !important;
    // color: black;
  }
  .item-after{
    font-weight: 500;
    color: black;
  }
  .item-text{
    font-weight: 600 !important;
    color: black;
  }
  .item-footer{
    font-weight: 600 !important;
    color: black;
  }
}

.content-calendar {
    background-color: var(--ha-dimgray);
    .list {
      .item-input {
        flex-direction: row-reverse;
        .item-media {
          padding-top: 0;
          align-self: center;
          padding-right: 5px;
          margin-left: 5px;
          .icon {
            width: 45px;
            height: 45px;
          }
        }

        .item-inner {
          background-color: #5f6a8c;
          border-radius: 3px;
          margin-left: 0;
          input {
            color: #dddddd !important;
            border: none !important;
            &::placeholder {
              color: #dddddd !important;
            }
          }
        }
      }
    }
  }
.content-list-template{
  height: calc(100vh - 104px);
  overflow-y: scroll;
  .content-nodata{
    width: 100%;
    flex-direction: column;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    &__title{
      font-size: 18px;
      font-weight: 500;
      margin-top: 10px;
      color: #a5a9b8;
    }
    &__description{
      font-size: 14px;
      font-weight: 300;
      margin-top: 10px;
      color: #a5a9b8;
      display: flex;
      align-items: center;
      img{
        width: 19px;
        height: 19px;
        margin: 0 3px;
      }
    }
  }
}
</style>
