import { f7 } from 'framework7-vue';
import * as Config from '../constants';
import store from '../store/index';
import Axios from 'axios';
import StringUtils from '../utilities/StringUtils';
import { buildMenu } from '../lib/menu';
import ResponseRepository from '../repositories/ResponseRepository';
import EventBus from '../EventBus';

export default {
  async hasNetworkConnexion(credentials) {
    const url = `${Config.API_BASE_URL}/api/token/`;
    return Axios.post(url, credentials)
      .then((response) => {
        return true
      })
      .catch((error) => {
        if (error.message.includes('Network')) {
          return false;
        } else {
          return true
        }
      });
  },

  login(credenciales) {
    const url = `${Config.API_BASE_URL}/api/token/`;

    const lastUser = localStorage.getItem('lastusername');
    if (lastUser != null) {
      if (lastUser !== credenciales.username) {
        localStorage.setItem('lastUpdate', 0);
      }
    }

    localStorage.setItem('lastusername', credenciales.username);

    return Axios
      .post(url, credenciales)
      .then(response => {
        this.handleLoginResult(response);
        return response.data;
      }).catch(error => {
        console.log('---------------- ERROR LOGIN -----------------');
        console.log(error);
        if (error.message.includes('Network')) {
          f7.dialog.alert('No hay conexión a red de datos');
        } else {
          f7.dialog.alert('Datos de acceso incorrectos');
        }
        return null;
      })
  },

  async autoLoginWeb(urlToken) {
    const url = `${Config.API_BASE_URL}/api/auto_login_web/`;

    return Axios.post(url, urlToken)
      .then((response) => {
        this.handleLoginResult(response);
        return response.data;
      })
      .catch((error) => {
        console.log('---------------- ERROR AUTO LOGIN WEB -----------------');
        console.log(error);
        f7.dialog.alert('No se ha podido iniciar sesión');
        return null;
      });
  },

  async updateDataLogin() {
    let user = store.getters.getToken;
    const url = `${Config.API_BASE_URL}/api/update_data_login/`;

    const data = {
      type: Config.APP_TYPE,
      appVersion: Config.APP_VERSION,
    };

    return Axios
      .post(url, data, { headers: { 'Authorization': 'Bearer ' + user.data.access } })
      .then(response => {
        return response.data;
      }).catch(error => {
        console.error(error);
      });
  },

  async handleLoginResult(result) {
    if (result.data.access) {
      store.commit('setToken', result);
      buildMenu(result.data);
      return result;
    }
    else {
      console.log(result);
    }
  },

  async changeCurrentPassword(password, oldPassword) {
    let user = store.getters.getToken;
    const url = `${Config.API_BASE_URL}/api/change_password/`;

    const username = localStorage.getItem('lastusername');
    const data = { username, password, old_password:oldPassword };

    return Axios
      .post(url, data, { headers: { 'Authorization': 'Bearer ' + user.data.access } })
      .then(response => {
        return response.data;
      }).catch(error => {
        console.error(error);
        return error.response.data
      });
  },

  async getServicesCompanyFromCache() {
    let user = store.getters.getToken;
    const url = `${Config.API_BASE_URL}/api/company_cache/`;
    const data = { groups: user.data.groups }

    return Axios
      .get(url, { headers: { 'Authorization': 'Bearer ' + user.data.access } })
      .then(response => response.data);
  },

  async getServicesUserFromCache() {
    let user = store.getters.getToken;
    const url = `${Config.API_BASE_URL}/api/user_cache/`;
    const data = { groups: user.data.groups }

    return Axios
      .get(url, { headers: { 'Authorization': 'Bearer ' + user.data.access } })
      .then(response => response.data);
  },

  getTemplates() {
    let user = store.getters.getToken;
    const url = `${Config.API_BASE_URL}/api/template/`;
    const data = { groups: user.data.groups }

    return Axios
      .post(url, data, { headers: { 'Authorization': 'Bearer ' + user.data.access } })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error(error)
      })
  },

  getVersionsTemplates() {
    let user = store.getters.getToken;
    const url = `${Config.API_BASE_URL}/api/template_versions/`;
    const data = { groups: user.data.groups }

    return Axios
      .post(url, data, { headers: { 'Authorization': 'Bearer ' + user.data.access } })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error(error)
      })
  },


  getVersionsTemplatesNew() {
    let user = store.getters.getToken;
    const url = `${Config.API_BASE_URL}/api/template_versions/`;

    const timeStamp = Number(localStorage.getItem('lastUpdate'));
    const dateStr = StringUtils.dateToFullString(new Date(timeStamp));

    const data = { fecha: dateStr };
    return Axios
      .post(url, data, { headers: { 'Authorization': 'Bearer ' + user.data.access } })
      .then(response => {
        return response.data;
      }).catch(error => {
        console.error(error);
        console.log('STATUS', error.response.status)
        if(error.response.status === 401) {
          EventBus.$emit('error.http', error.response);
        }
      })
  },


  getTemplatesNew() {
    let user = store.getters.getToken;
    const url = `${Config.API_BASE_URL}/api/template/`;

    const timeStamp = Number(localStorage.getItem('lastUpdate'));
    const dateStr = StringUtils.dateToFullString(new Date(timeStamp));

    const data = { fecha: dateStr };
    return Axios
      .post(url, data, { headers: { 'Authorization': 'Bearer ' + user.data.access } })
      .then(response => {
        return response.data;
      }).catch(error => {
        console.error(error);
      })
  },

  getResponses() {
    const user = store.getters.getToken;
    const url = `${Config.API_BASE_URL}/api/list_responses/`;
    const SYNC_RESP_LAST = store.getters.getSyncRespLast

    let fromDateTimestamp = null
    if (SYNC_RESP_LAST === 'no-limit') {
      fromDateTimestamp = 0
    } else {
      const auxDate = new Date()
      fromDateTimestamp = auxDate.setDate(auxDate.getDate() - SYNC_RESP_LAST);
    }
    const dateStr = StringUtils.dateToFullString(new Date(fromDateTimestamp));

    ResponseRepository.deleteListResponses(SYNC_RESP_LAST)

    const data = { fecha: dateStr };
    return Axios
      .post(url, data, { headers: { 'Authorization': 'Bearer ' + user.data.access } })
      .then(response => {
        return response.data;
      }).catch(error => {
        console.error(error);
      })
  },

  getResponsesNew() {
    const SEARCH_DATE_RANGE = store.getters.getSearchDataRange;
    const SYNC_RESP_LAST = store.getters.getSyncRespLast;
    const fromDate = new Date(store.state.Responses.dates[0]);
    const auxToDate = new Date(store.state.Responses.dates[1]);
    let toDate = new Date(auxToDate.setHours(23, 59, 59));

    let fromDateTimestamp = null
    if (SYNC_RESP_LAST === 'no-limit') {
      fromDateTimestamp = 0
    } else {
      const auxDate = new Date()
      fromDateTimestamp = auxDate.setDate(auxDate.getDate() - SYNC_RESP_LAST);
    }

    let timeStamp = null
    let selectedTimeStamp = null
    if (store.state.Responses.boolRefresh) {
      timeStamp = fromDate
      selectedTimeStamp = timeStamp
    } else {
      timeStamp = Number(localStorage.getItem('lastUpdate'));
      selectedTimeStamp = fromDateTimestamp > timeStamp ? fromDateTimestamp : timeStamp
      if (!SEARCH_DATE_RANGE) {
        ResponseRepository.deleteListResponses(SYNC_RESP_LAST);
      } else {
        ResponseRepository.deleteListResponsesFromTo(fromDate, toDate);
      }
    }

    let url = null
    let data = null
    const user = store.getters.getToken;
    let dateStr = StringUtils.dateToFullString(new Date(selectedTimeStamp));

    if (!SEARCH_DATE_RANGE) {
      // old way: get all data from dateFrom
      /* url = `${Config.API_BASE_URL}/api/list_responses/`;
      data = { fecha: dateStr }; */
      // new way: get only 1 day data
      url = `${Config.API_BASE_URL}/api/list_responses_date/`;
      data = { fecha: dateStr.split(' ')[0] };
    } else {

      const fromTemp = new Date(new Date(selectedTimeStamp)).setHours(0, 0, 0);
      dateStr = StringUtils.dateToFullString(new Date(fromTemp));

      url = `${Config.API_BASE_URL}/api/list_responses_range/`;
      toDate = toDate ? toDate : new Date((new Date(fromDate)).setHours(23, 59, 59));
      const toDateStr = StringUtils.dateToFullString(new Date(toDate));
      data = { desde: dateStr, hasta: toDateStr };
    }

    ResponseRepository.deleteListResponses(SYNC_RESP_LAST);
    return Axios
      .post(url, data, { headers: { 'Authorization': 'Bearer ' + user.data.access } })
      .then(response => {
        store.commit('Responses/SET_BOOL_REFRESH', false, { root: true })
        return response.data;
      }).catch(error => {
        console.error(error);
      })
  },

  getServices() {
    let user = store.getters.getToken;
    const url = `${Config.API_BASE_URL}/api/list_services/`;

    return Axios
      .get(url, { headers: { 'Authorization': 'Bearer ' + user.data.access } })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error(error)

      })

  },

  getAllServices() {
    let user = store.getters.getToken;
    const url = `${Config.API_BASE_URL}/api/all_services/`;

    return Axios
      .get(url, { headers: { 'Authorization': 'Bearer ' + user.data.access } })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error(error)
      })
  },

  getConfDatatype() {
    let user = store.getters.getToken;
    const url = `${Config.API_BASE_URL}/api/conf_data_type/`;

    return Axios
      .get(url, { headers: { 'Authorization': 'Bearer ' + user.data.access } })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error(error)
      })
  },

  getDetailTemplate(id) {
    let user = store.getters.getToken;
    const url = Config.API_BASE_URL + '/api/template/' + id + '/';

    return Axios
      .get(url, { headers: { 'Authorization': 'Bearer ' + user.data.access } })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error(error)
      })
  },

  saveTemplate(data) {
    let user = store.getters.getToken;
    const url = `${Config.API_BASE_URL}/api/save_template/`;
    return Axios
      .post(url, data, { headers: { 'Authorization': 'Bearer ' + user.data.access } })
      .then(response => {
        return response.data;
      }).catch(error => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        return error;
      })
  },
  updateTemplate(data) {
    let user = store.getters.getToken;
    const url = `${Config.API_BASE_URL}/api/save_template/`;
    return Axios
      .put(url, data, { headers: { 'Authorization': 'Bearer ' + user.data.access } })
      .then(response => {
        return response.data;
      }).catch(error => {
        console.error(error);
      })
  },

  saveTemplateResponse(data) {
    let user = store.getters.getToken;
    const url = `${Config.API_BASE_URL}/api/save_template_response/`;
    return Axios
      .post(url, data, { headers: { 'Authorization': 'Bearer ' + user.data.access } })
      .then(response => {
        return response.data;
      }).catch(error => {
        console.error('ERROR--------' , error);
        if(error.toJSON().message === 'Network Error'){
          // f7.dialog.alert('No hay conexión a red de datos. No se puede guardar en el servidor');
          f7.toast.create({
            text:'No hay conexión a red de datos. No se puede guardar en el servidor',
            closeTimeout: 2000,
          }).open();
        }
        return error;
      })
  },

  deleteTemplateResponse(id) {
    let user = store.getters.getToken;
    const url = `${Config.API_BASE_URL}/api/delete_template_response/${id}/`;
    return Axios
      .delete(url, { headers: { 'Authorization': 'Bearer ' + user.data.access } })
      .then(response => {
        return response.data;
      }).catch(error => {
        console.error(error);
      })
  },

  async executeService(data) {
    let user = store.getters.getToken;
    const url = `${Config.API_BASE_URL}/api/execute_service_json/`;
    return Axios
      .post(url, data, { headers: { 'Authorization': 'Bearer ' + user.data.access } })
      .then(response => {
        return response.data;
      }).catch(error => {
        console.log('STATUS', error.response.status)
        if(error.response.status === 401) {
          EventBus.$emit('error.http', error.response);
        }
      });
  },

  async externalCodeResponse(data) {
    let user = store.getters.getToken;
    const url = `${Config.API_BASE_URL}/api/external_code_response/`;
    return Axios
      .post(url, data, { headers: { 'Authorization': 'Bearer ' + user.data.access } })
      .then(response => {
        return response.data;
      });
  },

  async getExternalCode(id_response) {
    const data_service = {
        id: id_response,
      };
    const ext = await this.externalCodeResponse(data_service);
    return ext;
  },

  async getRegisterId(serie,numero) {
    const data_service = {
        code_service: 'CAL-REG',
        params: JSON.stringify([{'serie':serie}, {'numero':numero}]),
      };
    const idReg = await this.executeService(data_service);
    return idReg;
  },

  async getPDF(params, report) {
    const data_service = {
        code_service: 'CAL-PDF',
        params: JSON.stringify([{'ReportCode':report}, {'Parameters':params}]),
      };
    const pdf = await this.executeService(data_service);
    return pdf;
  },


  async sendEmail(data) {
    let user = store.getters.getToken;
    const url = `${Config.API_BASE_URL}/api/send_email/`;
    return Axios
      .post(url, data, { headers: { 'Authorization': 'Bearer ' + user.data.access } })
      .then(response => {
        return response.data;
      });
  },

  async registerDevice(data) {
    let user = store.getters.getToken;
    const url = `${Config.API_BASE_URL}/api/register_device/`;
    return Axios
      .post(url, data, { headers: { 'Authorization': 'Bearer ' + user.data.access } })
      .then(response => {
        return response.data;
      });
  },
  async fetchNotifications(data) {
    let user = store.getters.getToken;
    const url = `${Config.API_BASE_URL}/api/notifications/`;
    return Axios
      .post(url, data, { headers: { 'Authorization': 'Bearer ' + user.data.access } })
      .then(response => {
        return response.data;
      });
  },

  async sendNotification(data) {
    let user = store.getters.getToken;
    const url = `${Config.API_BASE_URL}/api/notification_update_template/`;
    return Axios
      .post(url, data, { headers: { 'Authorization': 'Bearer ' + user.data.access } })
      .then(response => {
        return response.data;
      });
  },

  async checkAsRead(notificationId) {
    let user = store.getters.getToken;
    const url = `${Config.API_BASE_URL}/api/check_read_notifications/`;
    const data = { ids: String(notificationId) };
    return Axios
      .post(url, data, { headers: { 'Authorization': 'Bearer ' + user.data.access } })
      .then(response => {
        return response.data;
      });
  },
  async checkAllAsRead() {
    let user = store.getters.getToken;
    const url = `${Config.API_BASE_URL}/api/check_read_all_notifications/`;
    return Axios
      .post(url, {}, { headers: { 'Authorization': 'Bearer ' + user.data.access } })
      .then(response => {
        return response.data;
      });
  },

  async getResponseById(id_response) {
    const data = {
      id: id_response,
    };
    let user = store.getters.getToken;
    const url = `${Config.API_BASE_URL}/api/get_response_by_id/`;
    return Axios
      .post(url, data, { headers: { 'Authorization': 'Bearer ' + user.data.access } })
      .then(response => {
        return response.data;
      });
  },
};
