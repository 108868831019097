// - General
export const APP_VERSION = '1.5.30 web';
export const APPLICATION_NAME = 'Calidad';
export const DATABASE_NAME = 'calidad';
export const APPLICATION_LOGO = 'static/img/logo.svg';

// export const API_BASE_URL = process.env.NODE_ENV == 'development' ? 'http://dev.dynamicforms.hispatecanalytics.com' : 'https://dynamicforms.hispatecanalytics.com';
export const API_BASE_URL = process.env.NODE_ENV == 'development' ? 'https://devdynamicforms.hispatecanalytics.com/' : 'https://dynamicforms.hispatecanalytics.com';

export const APP_TYPE = 'CALIDAD';

export const relevantUrls = {
  androidStore: 'https://play.google.com/store/apps/details?id=es.hispatec.appcalidad',
  iosStore: 'https://apps.apple.com/es/developer/grupo-hispatec-informatica-empresarial/id955482967?see-all=i-phonei-pad-apps'
};
