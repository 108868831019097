import store from '../store/index';

function buildMenu(data) {
  let template = store.getters.menuItems;
  let itemsMenuBottom = store.getters.getItemsMenuBottom;
  let itemMenuTop = store.getters.getItemsMenuTop;

  let activateNotifications = store.getters.getNotification;

  template.headerTopTitle = data.user;
  template.headerTopSubtitle = data.typeApp;
  template.items = [];
  template.items.push(itemMenuTop);
  for (let i = 0; i < data.menu.length; i++) {
    if (data.menu[i]) {
      template.items.push(JSON.parse(data.menu[i]));
    }
  }
  if(activateNotifications){
    const menuNotifications= {
      id: 3,
      groupId: 4,
      title: 'Notificaciones',
      favorite: true,
      showIcon: true,
      path: '/notifications/',
    };
    itemsMenuBottom.options.items.unshift(menuNotifications)
  }
  template.items.push(itemsMenuBottom);

  // Yes
  const params = data.params || [];

  // The amount of days that this user is allow to go back and edit a ""response""
  let dayCountToEdit = 0;
  for (let param of params) {
    if (param.name == 'PREVIOUS_DAYS_UPDATE') {
      dayCountToEdit = Number(param.value) || 0;
    }
  }

  // HACK: Admin should be able to edit everything.
  if (data.admin) {
    dayCountToEdit = 9999;
  }

  store.commit('setDayCountToEdit', dayCountToEdit);

  // Template Menu
  store.commit('setTemplate', template);
  return data;
}

export { buildMenu };
