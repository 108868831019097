import db from '../services/Database';
import moment from 'moment';

export default {
  async getAll() {
    return await db.responses.toArray();
  },

  async updateById(id, updateObj) {
    await db.responses
      .where('id')
      .equals(id)
      .modify({ sent: 1, idServer: updateObj.idServer, entities: updateObj.entities });
  },

  async edit(id, updateObj) {
    await db.responses.where('id').equals(id).modify(updateObj);
  },

  async getByTemplate(ids) {
    return await db.responses.where('template').anyOf(ids).toArray();
  },

  async getByTemplateFecha(id, dateStr) {
    return await db.responses
      .where('template')
      .anyOf(id)
      .and((item) => item.created_at.split('T')[0] === dateStr)
      .toArray()
      .then((items) => items.map(({ entities, extra_data, ...restoDeColumnas }) => restoDeColumnas));
  },

  async getByTemplateFechaRol(id, dateStr, rol) {
    return await db.responses
      .where('template')
      .anyOf(id)
      .and((item) => item.created_at.split('T')[0] === dateStr)
      .and((item) => parseInt(item.rol, 10) === parseInt(rol, 10))
      .toArray()
      .then((items) => items.map(({ entities, extra_data, ...restoDeColumnas }) => restoDeColumnas));
  },

  async getByTemplateRangoFecha(id, dates) {
    let a = moment(dates[0]);
    let b = moment(dates[1]);

    let arr = null;
    if (this.rol) {
      arr = await db.responses
        .where('template')
        .anyOf(id)
        .and((item) => parseInt(item.rol, 10) === parseInt(rol, 10))
        .toArray()
        .then((items) => items.map(({ entities, extra_data, ...restoDeColumnas }) => restoDeColumnas));
    } else {
      arr = await db.responses.where('template').anyOf(id).toArray().then((items) => items.map(({ entities, extra_data, ...restoDeColumnas }) => restoDeColumnas));
    }

    let result = [];
    arr.map(async (item) => {
      let current = item.created_at.split('T')[0];
      let compare = moment(item.created_at);
      if (
        (compare.isAfter(a) && compare.isBefore(b)) ||
        moment(current).isSame(b) ||
        moment(current).isSame(a)
      ) {
        result.push(item);
      }
    });

    return result;
  },

  async add(data) {
    await db.responses.add(data);
  },

  async getByUid(uid) {
    return await db.responses.where('response_uid').equals(uid).toArray();
  },

  async getPending() {
    return await db.responses.where('sent').equals(0).toArray();
  },

  async getPendingToUpload() {
    return await db.responses.where('sent').equals(0).limit(10).toArray();
  },

  async deleteById(id) {
    return await db.responses.where('id').equals(id).delete();
  },

  async deleteListResponses(days) {
    const arr = await db.responses.toArray();
    if (arr.length != 0) {
      let a = moment(new Date());
      let b = moment(a).subtract(days, 'days');

      arr.map(async (item) => {
        let compare = moment(item.created_at);
        if (item.sync && compare.isBefore(b)) {
          await db.responses.where('id').equals(item.id).delete();
        }
      });
    }
  },

  async deleteListResponsesFromTo(from, to) {
    const arr = await db.responses.toArray();
    if (arr.length != 0) {
      const dateFrom = moment(new Date(from));
      const dateTo = moment(new Date(to));

      arr.map(async (item) => {
        let compare = moment(item.created_at);
        if (item.sync && compare.isBetween(dateFrom, dateTo)) {
          await db.responses.where('id').equals(item.id).delete();
        }
      });
    }
  },

  async getById(ids) {
    return await db.responses.where('id').equals(parseInt(ids, 10)).first();
  },

  async updateByIdAutoSave(id, json) {
    await db.responses
      .where('id')
      .equals(id)
      .modify({ entities: json });
  },

  // async getLastUpdatedOwner() {
  //   return await db.responses.orderBy('updated_at').toArray();
  // },

  async updateByIdServerSync(id) {
    await db.responses
      .where('idServer')
      .equals(id)
      .modify({ sync: true });
  },
};