<template>
  <f7-app
    :params="f7params"
  >
    <hi-side-menu
      :template="menuItemsWithoutAdm"
      :show-icon="showIcon"
      :header-top-title="showTitle"
    />

    <task-loader />

    <!-- Your main view, should have "view-main" class -->
    <f7-view
      main
      class="safe-areas"
      url="/"
    />

    <event-bus-listener />
  </f7-app>
</template>
<script>
  import { Device }  from 'framework7/framework7-lite.esm.bundle';
  import cordovaApp from '../js/cordova-app';
  import routes from '../js/routes';
  // import EventBus from '../js/EventBus';
  // import HiSideMenu from 'vue-component-library/components/uitemplates/HiSideMenu.vue';
  import HiSideMenu from '../components/uitemplates/HiSideMenu.vue';
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import TaskLoader from './TaskLoader.vue';
  import EventBusListener from './event-listeners/EventBusListener.vue';
  import { buildMenu } from '../js/lib/menu'
  import { userAccess, updateOnlytemplates } from '../js/lib/shared'
  import ResponseRepository from '../js/repositories/ResponseRepository';

  export default {
    components: {
      HiSideMenu,
      TaskLoader,
      EventBusListener,
    },
    data() {
      return {
        tokenAlreadyRegistered: false,
        // Framework7 Parameters
        f7params: {
          id: 'es.hispatec.calidad', // App bundle ID
          name: 'Calidad', // App name
          theme: 'auto', // Automatic theme detection
          // App routes
          routes: routes,
          // Input settings
          input: {
            scrollIntoViewOnFocus: Device.cordova && !Device.electron,
            scrollIntoViewCentered: Device.cordova && !Device.electron,
          },
          // Cordova Statusbar settings
          statusbar: {
            iosOverlaysWebView: true,
            androidOverlaysWebView: false,
            androidTextColor: 'white',
            androidBackgroundColor: '#000000',
            iosTextColor: 'white',
            iosBackgroundColor: '#000000',
          },
        },
        // Login screen data
        username: '',
        password: '',
        title: 'Titulo',
      }
    },
    computed: {
      showTitle() {
        return 'Título';
      },
      showIcon() {
        return true;
      },
      menuItemsWithoutAdm() {
        let menu = null;
        if (Device.cordova) {
          menu = this.menuItems.items.map(x => {
            x.options.items = x.options.items.filter(item => !item.path.includes('/listTemplate'))
          })
          menu = this.menuItems;
          menu.items = menu.items.filter(x => { return x.options.items.length != 0 })
          return menu;
        }

        return this.menuItems;
      },
      ...mapGetters(['menuItems', 'getToken', 'getUpdateAutomatically']),
    },
    mounted() {
      this.$f7ready(async (f7) => {
        // Init cordova APIs (see cordova-app.js)
        await this.isExist()
        if (Device.cordova) {
          cordovaApp.init(f7);
          await this.configPushNotifications();
        }
        // Call F7 APIs here
    });
  },
  methods: {
    async signIn() {
      let usu = localStorage.getItem('username');
      let pass = localStorage.getItem('password');
      this.$f7.preloader.show();
      try {
        await userAccess(this.$f7, usu, pass);
      } catch (reason) {
        this.$f7.dialog.alert(reason);
      } finally {
        this.$f7.preloader.hide();
      }
    },
    logoutPressed() {
      const self = this;
      const borradores = Object.keys(localStorage).filter(x => x.startsWith('borrador_'));
      console.log('borradores', borradores);
      if(borradores) {
        this.$f7.dialog.confirm('Tiene plantillas en estado borrador. Seguro que quieres salir perdiendo la información?', () => {
          self.$f7.panel.close('left', false);
        });
      } else {
        this.$f7.dialog.confirm('Seguro que quieres salir?', () => {
          self.$f7.panel.close('left', false);
        });
      }

    },
    async checkCordovaConnectivity() {
      let usu = localStorage.getItem('username');
      let pass = localStorage.getItem('password');
      return await this.hasNetworkConnexion({user: usu, password: pass});
    },
    async isExist() {
      if(localStorage.getItem('username') != null && localStorage.getItem('password') != null) {
        let isOnline = navigator.onLine;
        if (Device.cordova) isOnline = await this.checkCordovaConnectivity();
        if(isOnline) {
          this.signIn();
        } else {
          const offlineMode = async () => {
            const token = await this.offlineLogin();
            buildMenu(token);
            if (token) {
              let _this = this
              setTimeout(() => { _this.$f7.views.main.router.navigate('/home/') }, 1000);
            } else {
              this.$f7.dialog.alert('No hay datos para poder continuar en modo offline');
            }
          }
          this.$f7.dialog.confirm(
            'No hay conexión ¿Desesa continuar en modo offline?',
             async () => { await offlineMode() }
          );
        }
      }
    },
    async configPushNotifications() {
      // FCM push notifications
      console.log('Device.cordova:', Device.cordova);
      console.log('FCM push notifications - test');

      window.FirebasePlugin.getToken(async (fcmToken) => {
        console.log('getToken:', fcmToken);
        this.tokenAlreadyRegistered = true;
        await this.registerDevice(fcmToken);
      }, (error) => { console.error(error); });

      window.FirebasePlugin.getId((appInstanceId) => {
        console.log('getId', appInstanceId);
      }, (error) => { console.error(error); });

      window.FirebasePlugin.onTokenRefresh(async (fcmToken) => {
        if (!this.tokenAlreadyRegistered) {
          console.log('onTokenRefresh:', fcmToken);
          await this.registerDevice(fcmToken);
        } else this.tokenAlreadyRegistered = false;
      }, (error) => { console.error(error); });

      window.FirebasePlugin.onMessageReceived(async (message) => {
        console.log('Message objext:', message);
        console.log(`Message type: ${message.messageType}`);
        console.log(`this.$f7.views.main.router.currentRoute.path: ${this.$f7.views.main.router.currentRoute.path}`);
        // message.tap
        // showUserMessage(message.messageType, message.messa)
        await this.handleNotifications(message);
        if (message.messageType === 'notification') {
          console.log('Notification message received');
          if (message.tap) { console.log(`Tapped in ${message.tap}`); }
        } console.dir(message);
      }, (error) => { console.error(error); });

      window.FirebasePlugin.grantPermission((hasPermission) => {
        console.log(`Permission was ${hasPermission ? 'granted' : 'denied'}`);
      });

      window.FirebasePlugin.hasPermission((hasPermission) => {
        if (hasPermission) console.log('Permission to receive notification is granted.');
        else console.log('Permission to receive notification is NOT granted.');
      });

      console.log('END -eeeeeeeeeee- FCM push notifications - test');
    },
    async registerDevice(fcmToken) {
      localStorage.setItem('fcmToken', fcmToken);
      // get device data
      console.log('fcmToken:', fcmToken);
      console.log('Device:', Device);
      console.log('window.device:', window.device);
      console.log('window.device.cordova:', window.device.cordova);
      console.log('window.device.model:', window.device.model);
      console.log('window.device.platform:', window.device.platform);
      console.log('window.device.uuid:', window.device.uuid);
      console.log('window.device.version:', window.device.version);
      console.log('window.device.manufacturer:', window.device.manufacturer);
      console.log('window.device.isVirtual:', window.device.isVirtual);
      console.log('window.device.serial:', window.device.serial);
      // register device endpoint and store data
      const registrationData = {
        notificationsToken: fcmToken,
        platform: window.device.platform,
        uuid: window.device.uuid,
        name: `${window.device.model} ${window.device.version}`,
      };
      this.storeDeviceData(registrationData);
      // }
    },
    async handleNotifications(message) {
      try {
        if (message?.tap === 'background') {
          console.log('handleNotificaions background', message);
          // localStorage.setItem('leidaSegundoPlano', JSON.stringify(message));
          // await navigateByTapping(message, this.$f7.views.main.router);
          // this.setPathOnStart(message);
        } else {

          const currentView = this.$f7.views.main.router.currentRoute.path;
          console.log('currentView:', currentView);
          const messageType = message.type_noti;
          if (messageType === 'TEMPLATE') {
              this.showUserMessage(message);
              if(this.getUpdateAutomatically) {
                await updateOnlytemplates();
              }
          } else if (messageType === 'CONTROL') {
            this.showUserMessage(message);
            if(message.object_id) {
              console.log('Se actualiza a sincronizado el idServer:', message.object_id);
              await ResponseRepository.updateByIdServerSync(parseInt(message.object_id, 10));
            }
          } else {
            this.showUserMessageClose(message)
          }
        }
      } catch (error) {
        console.error('Error handleNotifications:', error);
      }
    },
    showUserMessage(message) {
      console.log('showUserMessage - message:', message);
      this.$f7.notification.create({
        title: message.title,
        titleRightText: 'ahora',
        text: message.body,
        closeTimeout: 6000,
      }).open();
    },
    showUserMessageClose(message) {
      this.$f7.notification.create({
        title: message.title,
        titleRightText: 'ahora',
        text: message.body,
        closeButton: true,
      }).open();
    },
    ...mapMutations('EntityCacheSearch', ['SET_LIMIT_PREVIEW']),
    ...mapActions([
      'login',
      'offlineLogin',
      'offlineModeOn',
      'offlineModeOff',
      'hasNetworkConnexion',
      'storeDeviceData',])
  }
}
</script>