<template>
  <f7-page name="completeTemplate">
    <navbar
      slot="fixed"
      nav-title="Completar formulario"
      back-btn-enable
      :img-btn-enable="canViewImages"
      :img-btn-callback="() => $refs.popup.open()"
      :doc-btn-enable="canViewDocs"
      :doc-btn-callback="() => $refs.popupDocs.open()"
      :save-btn-enable="showAutoSave"
      :save-btn-callback="autoSave"
      :save-top-btn-enable="showTopSave"
      :save-top-btn-callback="guardar"
    />

    <f7-row class="padding-left padding-top justify-content-start align-items-center">
      <div class="padding-right">
        Fecha de Registro:
      </div>
      <f7-input
        input-style="padding: 0 !important; text-align: center"
        class="padding-half"
        style="max-width: 150px"
        outline
        type="datepicker"
        :value="[registerDateInput]"
        :calendar-params="{
          openIn: 'customModal',
          closeOnSelect: true,
          dateFormat: 'dd-mm-yyyy HH::mm',
          timePicker: true,
        }"
        @calendar:change="onChangeDate"
      />
    </f7-row>

    <f7-photo-browser
      ref="popup"
      :photos="photos_browser"
      type="popup"
      :thumbs="thumbs"
      theme="dark"
      popup-close-link-text="Cerrar"
      :routable-modals="false"
    />

    <f7-popup
      ref="popupDocs"
      :tablet-fullscreen="true"
    >
      <f7-page>
        <f7-navbar title="Documentos">
          <f7-nav-right>
            <f7-link
              popup-close
              @click="setContentPDF('')"
            >
              Cerrar
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <f7-list
            media-list
            dividers-ios
            strong-ios
            outline-ios
          >
            <f7-list-item
              v-for="(option, index) in documents"
              :key="index"
              link="#"
              :title="'Documento ' + (index+1)"
              @click="setContentPDF(option)"
            >
              <p>{{ option.name }}</p>
            </f7-list-item>
          </f7-list>

          <div>
            <p-d-f-viewer
              v-if="contentPDF"
              :source="contentPDF"
              style="height: 100vh; width: 100%; min-width: 100%"
              @download="handleDownload"
            />
          </div>
        </f7-block>
      </f7-page>
    </f7-popup>

    <hi-forms-visualizer
      v-if="!isDataLoading"
      :template="JSON.parse(currentTemplate.data)"
      :show-button="false"
      :service-url="urlService"
      :service-token="token.data.access"
      :template-external-data="Object.assign(infoEntity?infoEntity:{}, token )"
      :groups-close="getCloseAccordionForm"
      class="margin-bottom"
      @form-data="getData"
    />
    <f7-block class="margin-bottom">
      <f7-button
        fill
        @click="guardar"
      >
        Guardar
      </f7-button>
    </f7-block>
  </f7-page>
</template>

<script>
import Navbar from '../components/Navbar.vue';
// import HiFormsVisualizer from 'vue-component-library/components/dynamicforms/HiFormsVisualizer.vue';
import HiFormsVisualizer from '../components/dynamicforms/HiFormsVisualizer.vue';
import * as Config from '../js/constants';
import { mapState, mapGetters } from 'vuex';
import EventBus from '../js/EventBus';
import StringUtils from '../js/utilities/StringUtils';
import { checkRequiredInputs } from '../js/lib/shared'
import Api from '../js/services/Api';
import ResponseRepository from '../js/repositories/ResponseRepository';
import PDFViewer from 'pdf-viewer-vue/dist/vue2-pdf-viewer';

export default {
  name: 'CompleteTemplate',
  components: { HiFormsVisualizer, Navbar, PDFViewer },
  data() {
    return {
      template: null,
      resultado: null,
      resultadoExtra: null,
      resultadoExternal: null,
      requiredInputs: [],
      areRequiredInputsDefined: false,
      registerDate: null,
      registerDateInput: new Date(),
      allowCreateNewResponse: true,
      isDataLoading: true,
      photos: [],
      photos_browser:[],
      documents: [],
      thumbs: [],
      popupPhotosOpened: false,
      canViewImages: false,
      canViewDocs: false,
      showAutoSave: false,
      checkShowAutoSave: true,
      idServerAutosave: 0,
      idAutosave: 0,
      contentPDF: '',
      showTopSave: false,
      topSaveRedirect: false
    };
  },
  computed: {
    urlService() {
      return Config.API_BASE_URL;
    },
    ...mapState([
      'currentTemplate',
      'confDatatypes',
      'token',
      'infoCurrentTemplate',
      'infoEntity',
      'latitude',
      'longitude',
      'currentRol',
    ]),
    ...mapGetters(['getQuestionShare', 'getAutoSave', 'getTopSave','getTopSaveRedirect', 'getCloseAccordionForm'])
  },
  created() {
    // Gestion del auto-guardado
    const tmpAuto = this.getAutoSave;
    if(tmpAuto !== false){
      if(tmpAuto === 'MANUAL') {
        this.showAutoSave = true;
      }
    }
    if(!this.showAutoSave) {
      const topsave = this.getTopSave;
      if(topsave) {
        this.showTopSave = true;
        this.topSaveRedirect = this.getTopSaveRedirect;
      }
    }
  },
  async mounted() {
    console.log('completeTemplate - mounted')
    this.isDataLoading = true;
    try {
      this.defineRequiredInputs();
      this.defineRequiredInputs();
    } catch (e) {
      console.error(e)
    } finally {
      this.isDataLoading = false;
    }

    if(this.infoEntity !== null && this.infoEntity.id !== null && this.currentTemplate.entity_type_images) {
      this.photos = await this.getPhotos();

      if(this.photos && this.photos.length>0) {

        this.photos.forEach(photo=>{
          console.log('photo', photo)
          let extension = '';
          if(photo.FileName) {
            extension = photo.FileName.split('.').pop();
            console.log('extension:' + extension)
          }
          let tmp = {};
          if(extension === 'pdf') {
            this.canViewDocs = true;
            let fileName = photo.Name;
            if(!fileName) {
              try{
                fileName = photo.FileName.split('\\')[photo.FileName.split('\\').length-1]
              }catch(err) {
                fileName = 'Sin nombre';
              }
            }
            tmp = {
              // 'url':'data:application/pdf;base64,'+photo.Base64File+'',
              // 'caption': '<iframe src="data:application/pdf;base64,'+photo.Base64File+'" height="100%" width="100%"></iframe>"'
              'name': fileName,
              'url':'data:application/pdf;base64,'+photo.Base64File+''
            }
            this.documents.push(tmp)
          } else {
            this.canViewImages = true;
            tmp = {
              'url':'data:image/png;base64,'+photo.Base64File,
              'caption': photo.Name
            }
            this.photos_browser.push(tmp);
            this.thumbs.push('data:image/png;base64,'+photo.Base64File);
          }
        })
      }
    }

    if(this.getQuestionShare && this.currentTemplate.code_print) {
      console.log('Se debe preguntar si desea compartir......');
    }

  },
  methods: {
    async handleDownload({src, filename}) {
      if(!this.$f7.device.cordova) {
        const pdfTmp = new File([src], 'calidad.pdf', { type: 'application/pdf' });
          navigator.share({
            title: this.token.data.full_name,
            text: 'PDF Calidad',
            files: [pdfTmp]
          })
      } else {
        window.plugins.socialsharing.share(this.token.data.full_name, 'PDF Calidad', src, null);
      }
    },
    setContentPDF(obj) {
      this.contentPDF = obj.url;
    },
    async autoSave() {
      if(this.checkShowAutoSave === false) {
        console.log('La respuesta ya se está guardando')
        return;
      }
      this.checkShowAutoSave = false;
      console.log('completeTemplate - autosave')
      this.$f7.toast.create({
        text:'Guardando respuesta en el servidor',
      }).open();

      this.allowCreateNewResponse = false;
      this.areRequiredInputsDefined = checkRequiredInputs(this.resultado, this.requiredInputs);
      if (this.areRequiredInputsDefined) {

        let newInfoEntity = this.infoEntity;
        if(this.infoEntity === null || this.infoEntity === 'null') {
          if(this.currentTemplate.fieldsVisualizer) {
            newInfoEntity = {'name':''}
            const fieldsVisualizer = this.currentTemplate.fieldsVisualizer.split(',');
            let allValues = '';
            fieldsVisualizer.forEach(field => {
              if(field) {
                allValues = allValues + ' - ' + this.resultado[field];
              }
            })
            newInfoEntity['name'] = allValues;
          }
        }

        let data = {};
        if(this.idAutosave === 0) {
          console.log('Creacion....');
          // Creacion
          data = {
            entities: JSON.stringify(this.resultado),
            entities_id: this.infoCurrentTemplate.idItem,
            template: this.currentTemplate.id,
            version: this.currentTemplate.current_version,
            user_created: this.token.data.id,
            user_updated: this.token.data.id,
            sent: 0,
            sync: false,
            created_at: StringUtils.dateToFullString(this.registerDate, 'T'),
            updated_at: StringUtils.dateToFullString(new Date(), 'T'),
            idServer: this.idServerAutosave,
            nameEntity: this.currentTemplate.entityName,
            extra_data: JSON.stringify(this.resultadoExtra),
            // info_entity: JSON.stringify(this.infoEntity),
            info_entity: JSON.stringify(newInfoEntity),
            nameUser: this.token.data.user,
            latitude: this.latitude,
            longitude: this.longitude,
            rol: this.currentRol,
            response_uid: `${this.token.data.id}|${Date.now()}`
          };

          await ResponseRepository.add(data);
          const responseLocal = await ResponseRepository.getByUid(data.response_uid);
          const idLocal = responseLocal[0].id;
          this.idAutosave = idLocal;
          // Api.saveTemplateResponse(data);
          const response = await Api.saveTemplateResponse(data);
          if(response.idServer) {
            await ResponseRepository.updateById(idLocal, response);
          }
          if(!response) {
            this.$f7.toast.close();
          }
        } else {
          // Edicion
          console.log('Edicion....');

          data = {
            entities: JSON.stringify(this.resultado),
            extra_data: JSON.stringify(this.resultadoExtra),
            created_at: StringUtils.dateToFullString(this.registerDate, 'T'),
            updated_at: StringUtils.dateToFullString(new Date(), 'T'),
            user_updated: this.token.data.id,
            nameUser: this.token.data.user,
            sent: 0,
          };
          await ResponseRepository.edit(this.idAutosave, data);
          const respEdit = await ResponseRepository.getById(this.idAutosave);
          const response = await Api.saveTemplateResponse(respEdit);
          if(response.idServer) {
            await ResponseRepository.updateById(this.idAutosave, response);
          }
          if(!response) {
            this.$f7.toast.close();
          }
        }


        this.checkShowAutoSave = true;

        this.$f7.toast.close();
      } else {
        this.$f7.dialog.alert('Revise los campos requeridos, quedan algunos sin cumplimentar');
        this.allowCreateNewResponse = true;
        this.$f7.toast.close();
        this.checkShowAutoSave = true;
      }


    },
    openPopupImages() {
      this.popupPhotosOpened = true;
      $refs.popup.open();
    },
    async getPhotos() {
      const data_service = {
          code_service: 'CAL-IMAGES',
          params: JSON.stringify([{'entityType':this.currentTemplate.entity_type_images}, {'entityId':this.infoEntity.id}]),
        };
      const photos = await Api.executeService(data_service);
      return photos;
    },

    onChangeDate(e) {
      if (e && e[0]) this.registerDate = e[0];
    },
    defineRequiredInputs() {
      const parsedCurrentTemplate = JSON.parse(this.currentTemplate.data);
      const { detail } = parsedCurrentTemplate;
      let requiredInputs = [];
      detail.forEach(group => {
        if (group.type === 'LIST_TEMPLATE') {
          group.children.forEach(groupLayout => {
            requiredInputs = [...requiredInputs, ...this.getRequiredInputsForGroupLayoutFrom(groupLayout.children, groupLayout.refGroup)]
          })
        } else {
          requiredInputs = [...requiredInputs, ...this.getRequiredInputsForGroupLayoutFrom(group.children)];
        }
      })
      this.requiredInputs = requiredInputs;
    },
    getRequiredInputsForGroupLayoutFrom(inputs, groupLayoutReference) {
      return inputs
        .filter((input) => input.options.required)
        .map(input => {
          return {
            reference: input.ref,
            type: input.dataType,
            groupRef: groupLayoutReference,
          }
        });
    },
    getData({ data, extraData, externalData }) {
      console.log(this.$f7.views.main.router.currentRoute.path)
      this.resultado = data;
      this.resultadoExtra = extraData;
      this.resultadoExternal = externalData;
    },
    checkRoute() {
      if(this.$f7.views.main.router.currentRoute.path !== '/completetemplate/' || this.$f7.views.main.router.currentRoute.url !== '/completetemplate/') {
        this.$f7.views.main.router.currentRoute.path = '/completetemplate/';
        this.$f7.views.main.router.currentRoute.url = '/completetemplate/';
        }
    },
    async guardar() {
      if (this.allowCreateNewResponse === false){
        if(this.idAutosave !== 0) {
          await this.autoSave();

          const data = await ResponseRepository.getById(this.idAutosave);
          if(this.getQuestionShare && this.currentTemplate.code_print) {
            this.saveServer(data, false)
          } else {
            EventBus.$emit('responses.update', { id: this.idAutosave, response: data });
            this.checkRoute();
            this.$f7.views.main.router.navigate('/home/', {reloadAll: true, history: false});
          }


          // this.$f7.views.main.router.navigate('/home/', {reloadAll: true, history: false});
          return
        }
        return
      }

      //
      this.allowCreateNewResponse = false;
      this.areRequiredInputsDefined = checkRequiredInputs(this.resultado, this.requiredInputs);
      if (this.areRequiredInputsDefined) {

        let newInfoEntity = this.infoEntity;
        if(this.infoEntity === null || this.infoEntity === 'null') {
          if(this.currentTemplate.fieldsVisualizer) {
            newInfoEntity = {'name':''}
            const fieldsVisualizer = this.currentTemplate.fieldsVisualizer.split(',');
            let allValues = '';
            fieldsVisualizer.forEach(field => {
              if(field) {
                allValues = allValues + ' - ' + this.resultado[field];
              }
            })
            newInfoEntity['name'] = allValues;
          }
        }

        let data = {
          entities: JSON.stringify(this.resultado),
          entities_id: this.infoCurrentTemplate.idItem,
          template: this.currentTemplate.id,
          version: this.currentTemplate.current_version,
          user_created: this.token.data.id,
          user_updated: this.token.data.id,
          sent: 0,
          sync: false,
          created_at: StringUtils.dateToFullString(this.registerDate, 'T'),
          updated_at: StringUtils.dateToFullString(new Date(), 'T'),
          idServer: 0,
          nameEntity: this.currentTemplate.entityName,
          extra_data: JSON.stringify(this.resultadoExtra),
          // info_entity: JSON.stringify(this.infoEntity),
          info_entity: JSON.stringify(newInfoEntity),
          nameUser: this.token.data.user,
          latitude: this.latitude,
          longitude: this.longitude,
          rol: this.currentRol,
          response_uid: `${this.token.data.id}|${Date.now()}`
        };


        if(this.getQuestionShare && this.currentTemplate.code_print) {
          this.saveServer(data)
        } else {
          EventBus.$emit('responses.insert', data);
          this.checkRoute();
          if(this.topSaveRedirect) {
            this.$f7.views.main.router.navigate('/newForm/', {reloadAll: true, history: false});
          } else {
            this.$f7.views.main.router.navigate('/home/', {reloadAll: true, history: false});
          }
        }


      } else {
        this.$f7.dialog.alert('Revise los campos requeridos, quedan algunos sin cumplimentar');
        this.allowCreateNewResponse = true;
      }
    },
    async saveServer(data, insert=true) {
      this.$f7.dialog.preloader('Guardando la respuesta en el servidor...');
      if (insert) {
        await ResponseRepository.add(data);
      }
      const responseLocal = await ResponseRepository.getByUid(data.response_uid);

      const idLocal = responseLocal[0].id;

      const response = await Api.saveTemplateResponse(data)

      await ResponseRepository.updateById(idLocal, response);

      const self = this;
      this.$f7.dialog.close();

      this.$f7.dialog
        .create({
          title: '¿Quieres compartir el formulario?',
          buttons: [
            {
              text: 'Si',
            },
            {
              text: 'No',
            },
          ],
          onClick: async function (dialog, index) {
            if(index === 0){
                // const respCodeExt = await self.getExternalCode(response.idServer)
                self.$f7.dialog.preloader('Obteniendo la sincronización del formulario...');
                let namePDF = 'calidad.pdf';
                try {
                  if(response.info_entity && response.info_entity !== 'null') {
                    namePDF = JSON.parse(response.info_entity).name+ '.pdf';
                    namePDF = namePDF.replace(/[/\\?%*:|"<>]/g, '-');
                  } else {
                    namePDF = response.nameUser + '-' + response.created_at + '.pdf';
                    namePDF = namePDF.replace(/[/\\?%*:|"<>]/g, '-');
                  }
                } catch (error) {
                  namePDF = 'calidad.pdf';
                }
                console.log('namePDF', namePDF);
                setTimeout(async() => {
                  const respCodeExt = await Api.getExternalCode(response.idServer)

                  if(respCodeExt.code_external && respCodeExt.code_external !== '') {
                    if(respCodeExt.code_external.includes('/')) {
                      const serie = respCodeExt.code_external.split('/')[0];
                      const numero = respCodeExt.code_external.split('/')[1];

                      const register = await Api.getRegisterId(serie,numero);


                      self.$f7.dialog.close();

                      if(register && register.length > 0) {
                        const paramTemp = [{
                          'ParameterName': 'idcontrolcalidad',
                          'ParameterValue': register[0].id + ''
                        }]
                        self.$f7.dialog.preloader('Obteniendo el pdf...');
                        const pdf = await Api.getPDF(paramTemp, self.currentTemplate.code_print);

                        self.$f7.dialog.close();
                        if(!pdf.PDF && pdf.Error) {
                          self.$f7.dialog.alert('Error obteniendo el pdf: ' + pdf.Error);
                          self.$f7.views.main.router.navigate('/home/',{reloadAll: true, history: false});
                        } else {
                          await self.socialSharing(pdf.PDF, namePDF);
                          // self.$f7.views.main.router.navigate('/home/', {reloadAll: true, history: false});
                        }
                      }
                      else {
                        self.$f7.dialog.close();
                        self.$f7.dialog.alert('No se ha podido obtener el registro de calidad para obtener su pdf. Pruebe a compartirlo desde el listado');
                        self.checkRoute();
                        self.$f7.views.main.router.navigate('/home/', {reloadAll: true, history: false});
                      }
                    }
                  } else {
                    self.$f7.dialog.close();
                    self.$f7.dialog.alert('Aún no se ha sincronizado el formulario. Pruebe a compartirlo desde el listado');
                    self.checkRoute();
                    self.$f7.views.main.router.navigate('/home/', {reloadAll: true, history: false});
                  }

              }, 5000);


            }
            else if(index === 1){
              self.checkRoute();
              self.$f7.views.main.router.navigate('/home/', {reloadAll: true, history: false});
            }
        },
          verticalButtons: true,
        })
        .open();
    },
    socialSharing(pdf, namePDF) {
      let name = 'calidad.pdf';
      if(namePDF) {
        name = namePDF;
      }
      const pdfTmp = new File([pdf], name, { type: 'application/pdf' });
      if(!this.$f7.device.cordova) {
        const self = this;
        this.$f7.dialog.confirm('¿Seguro que desea compartir?', async () => {
          navigator.share({
            title: this.token.data.full_name,
            text: name,
            files: [pdfTmp]
          })
          self.checkRoute();
          self.$f7.views.main.router.navigate('/home/', {reloadAll: true, history: false});
        });
      } else {
        window.plugins.socialsharing.share(this.token.data.full_name, name, 'data:application/pdf;base64,'+pdf, null);
        this.checkRoute();
        this.$f7.views.main.router.navigate('/home/', {reloadAll: true, history: false});
      }
    },
  },
};
</script>

<style lang="scss">
.page-next {
  pointer-events: visible!important;
}
</style>